$(document).ready(function(){
    //TRIGGER RESPONSIVE MENU
    $('#trigger').click(function(){
        if($(this).hasClass('open')){
            $(this).removeClass('open');
            $('header nav ul').slideUp();
        }else{
            $(this).addClass('open');
            $('header nav ul').slideDown();
        }
    });

    //FANCYBOX
    $("a.imagesgroup").fancybox({
		'transitionIn'	:	'elastic',
		'transitionOut'	:	'elastic',
		'speedIn'		:	600,
		'speedOut'		:	200,
		'overlayShow'	:	false
	});

    //CUSTOM LINK MENU CLICK
    var url = window.location.href; // Returns path only
    console.log();
    if(url == window.location.protocol + "//" + window.location.host + "/#waaromSMCB"){
        $('nav ul.menu li.menu-item-type-custom').addClass('active');
    }
    $('nav ul.menu li.menu-item-type-custom').click(function(){
        $(this).addClass('active');
    });

    //EQUAL HEIGHT MAP AND FORM
      function resizeshit(){
        $heightForm = $("#contact #form").outerHeight();
        $("#contact #map").css({height: $heightForm,});

        $widthButtons = $("#klassenwerk .button").width();
        $("#klassenwerk .button").css({height: $widthButtons,});
      }
      resizeshit();

      $(window).resize(function(){
          resizeshit();
      });

    //ANCHOR SMOOTH SCROLL
    $('a[href*="#"]')
      .not('[href="#"]')
      .not('[href="#0"]')
      .click(function(event) {
        if (
          location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '')
          &&
          location.hostname == this.hostname
        ) {
          var target = $(this.hash);
          target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
          if (target.length) {
            event.preventDefault();
            $('html, body').animate({
              scrollTop: target.offset().top
            }, 1000, function() {
              var $target = $(target);
              $target.focus();
              if ($target.is(":focus")) { // Checking if the target was focused
                return false;
              } else {
                $target.attr('tabindex','-1'); // Adding tabindex for elements not focusable
                $target.focus(); // Set focus again
              };
            });
          }
        }
      });
});


//SCROLL TO HOMEPAGE COUNTER ACTIVE
if($('body').hasClass('home')){
    var count = 0;
    $(window).scroll(function() {
       var scroll = $(window).scrollTop();
       var os = $('.home #news').offset().top + 100;

       if(scroll > os){
          if(count == 1){
              console.log('hallo');
               $('.number').each(function () {
                   $(this).prop('Counter',0).animate({
                       Counter: $(this).text()
                   }, {
                       duration: 2000,
                       easing: 'swing',
                       step: function (now) {
                           $(this).text(Math.ceil(now));
                       }
                   });
               });
           }
           count++;
       }
    });
}


equalheight = function(container){
var currentTallest = 0,
     currentRowStart = 0,
     rowDivs = new Array(),
     $el,
     topPosition = 0;
 $(container).each(function() {
   $el = $(this);
   $($el).height('auto')
   topPostion = $el.position().top;
   if (currentRowStart != topPostion) {
     for (currentDiv = 0 ; currentDiv < rowDivs.length ; currentDiv++) {
       rowDivs[currentDiv].height(currentTallest);
     }
     rowDivs.length = 0; // empty the array
     currentRowStart = topPostion;
     currentTallest = $el.height();
     rowDivs.push($el);
   } else {
     rowDivs.push($el);
     currentTallest = (currentTallest < $el.height()) ? ($el.height()) : (currentTallest);
  }
   for (currentDiv = 0 ; currentDiv < rowDivs.length ; currentDiv++) {
     rowDivs[currentDiv].height(currentTallest);
   }
 });
}
$(window).load(function() {
  equalheight('#news .equal');
  equalheight('#news ul li');
  equalheight('#waaromSMCB .content ul li .colorTitle');
  equalheight('#waaromSMCB .content ul li .tekst');
  equalheight('#zoeken ul.searchgrid li');
});
$(window).resize(function(){
    equalheight('#news .equal');
    equalheight('#news ul li');
    equalheight('#waaromSMCB .content ul li .colorTitle');
    equalheight('#waaromSMCB .content ul li .tekst');
    equalheight('#zoeken ul.searchgrid li');

});
